<script setup lang="ts">
const emits = defineEmits<{
  'update:modelValue': [value: boolean]
}>()

const props = withDefaults(
  defineProps<{
    arrowPosition?: 'bottom' | 'right'
    buttonAttrs?: Record<string, string>
    buttonText?: Record<'open' | 'close', string>
    color?: 'primary' | 'secondary' | 'grey' | 'black'
    contentPosition?: 'down' | 'up'
    icon?: boolean
    modelValue?: boolean
  }>(),
  {
    arrowPosition: 'bottom',
    buttonAttrs: () => ({}),
    buttonText: () => ({ open: '', close: '' }),
    color: 'primary',
    contentPosition: 'down',
    icon: true,
    modelValue: false,
  },
)

const collapseContent = ref<HTMLDivElement | null>(null)
const collapse = ref<HTMLDivElement | null>(null)

const buttonClass = computed(() => {
  const buttonClass = [
    'lc-collapse-arrow',
    `lc-collapse-arrow--${props.arrowPosition}`,
  ]

  if (props.buttonAttrs?.class) {
    buttonClass.push(props.buttonAttrs.class)
  }

  if (props.modelValue) {
    buttonClass.push(`lc-collapse-arrow--${props.arrowPosition}--active`)
  }

  return buttonClass
})

const text = computed(() => {
  if (!props.buttonText?.open && !props.buttonText?.close) {
    return ''
  }

  if (!props.modelValue) {
    return props.buttonText.close
  }

  return props.buttonText.open
})

watch(
  () => props.modelValue,
  (val) => {
    calculateContentHeight(val)
  },
)

const calculateContentHeight = (newVal: boolean) => {
  const content = collapseContent.value

  if (!newVal) {
    if (content?.style) {
      content.style.maxHeight = ''
    }

    const element = collapse.value
    const offsetTop = element?.getBoundingClientRect()?.top

    if (offsetTop && Math.sign(offsetTop) < 0) {
      useScrollTo({ id: element, offset: -200 })
    }
  } else if (content) {
    content.style.maxHeight = `${content.scrollHeight}px`
  }
}
const toggle = () => {
  emits('update:modelValue', !props.modelValue)
}

onMounted(() => calculateContentHeight(props.modelValue))
</script>

<template>
  <div
    ref="collapse"
    :class="[
      'flex lc-collapse',
      {
        'flex-col-reverse': contentPosition === 'up',
        'flex-col': contentPosition === 'down',
      },
    ]"
  >
    <base-button
      :class="buttonClass"
      :color="color"
      class="base-collapse__button"
      v-bind="buttonAttrs"
      variant="link"
      data-testid="base-collapse__button"
      @click.stop="toggle"
    >
      <span v-if="text" class="mr-2">
        {{ text }}
      </span>
      <BaseIcon v-if="icon" class="lc-collapse-icon" name="navArrowDown" />
    </base-button>

    <div ref="collapseContent" class="lc-collapse-content">
      <slot />
    </div>
  </div>
</template>

<style>
.lc-collapse-content {
  transition: max-height 0.75s ease-in-out;
  @apply max-h-0 overflow-hidden;
}

.lc-link.lc-collapse-arrow {
  @apply flex items-center w-max leading-none;
}

.lc-link.lc-collapse-arrow:focus {
  box-shadow: none;
}

.lc-link.lc-collapse-arrow .lc-collapse-icon {
  @apply font-black block h-[10px];
  min-height: 10px;
}
.lc-link.lc-collapse-arrow .lc-collapse-icon:before {
  @apply block h-[10px] text-[0.75rem];
}

.lc-link.lc-collapse-arrow--bottom .lc-collapse-icon {
  transform: rotate(0deg);
}
.lc-link.lc-collapse-arrow--bottom--active .lc-collapse-icon {
  transform: rotate(-180deg);
}

.lc-link.lc-collapse-arrow--right .lc-collapse-icon {
  transform: rotate(-90deg);
}
.lc-link.lc-collapse-arrow--right--active .lc-collapse-icon {
  transform: rotate(0deg);
}
</style>
